<template>
  <div class="contact">
    <validator-form
      @success="submit(getForm())"
      class="pure-g pure-form"
      :get-form="getForm"
    >
      <div class="contact__header pure-u-1">
        <div
          class="h3"
          ref="contactFormHeaderRef"
        >
          {{ $tv('contactUs', 'contactForm') }}
        </div>
        <div
          class="h6 subheader"
        >
          {{ $tv('questions', 'contactForm') }}
        </div>
        <div class="subheader__description">
          {{ $tv('looking', 'contactForm') }}
        </div>
      </div>
      <div class="contact__message pure-u-1">
        <div>
          <label
            class="label"
            for="ddlMessages"
          >{{ $tv('kindOfMessage', 'contactForm') }}*</label>
          <DropDown
            :data-items="messages"
            text-field="text"
            data-item-key="value"
            name="Type"
            @change="setType"
            :value="form.Type"
            :default-item="defaultItemMessage"
            :rules="[getRequiredRule('kindOfMessageFieldName')]"
            id="ddlMessages"
            ref="validationFormMessages"
          />
        </div>
      </div>
      <hr>
      <div class="contact__body pure-u-1 pure-g">
        <div class="pure-g">
          <div class="contact__body__formItem pure-u-1 pure-u-md-1-2 pure-u-xl-1-6">
            <div>
              <label
                class="label"
                for="ddlSalutation"
              >{{ $tv('salutation', 'contactForm') }}*</label>
              <DropDown
                :data-items="salutations"
                text-field="text"
                data-item-key="value"
                name="Salutation"
                @change="setTitle"
                :value="form.Salutation"
                :rules="[getRequiredRule('salutation')]"
                id="ddlSalutation"
                ref="validationFormSalutations"
              />
            </div>
          </div>
          <div class="contact__body__formItem pure-u-1 pure-u-md-1-2 pure-u-xl-1-2">
            <div>
              <label
                class="label"
                for="firstname"
              >{{ $tv('firstName', 'contactForm') }}*</label>
              <InputText
                type="text"
                id="firstname"
                v-model="form.FirstName"
                class="input"
                name="FirstName"
                :rules="[getRequiredRule('firstName')]"
                ref="validationFormFirstName"
              />
            </div>
          </div>
          <div class="contact__body__formItem pure-u-1 pure-u-md-1-2 pure-u-xl-1-3">
            <div>
              <label
                class="label"
                for="lastname"
              >{{ $tv('lastName', 'contactForm') }}*</label>
              <InputText
                type="text"
                id="lastname"
                name="LastName"
                v-model="form.LastName"
                :rules="[getRequiredRule('lastName')]"
                ref="validationFormLastName"
              />
            </div>
          </div>
        </div>
        <div class="contact__form-folding-container pure-g">
          <div class="contact__body__formItem pure-u-1 pure-u-md-1-2 pure-u-xl-2-3">
            <div>
              <label
                class="label"
                for="email"
              >{{ $tv('email', 'contactForm') }}*</label>
              <InputText
                type="text"
                id="email"
                name="Email"
                v-model="form.Email"
                :rules="[getRequiredRule('email')]"
                ref="validationFormEmail"
              />
            </div>
          </div>
          <div class="contact__body__formItem pure-u-1 pure-u-md-1-2 pure-u-xl-1-3">
            <div>
              <label
                for="ddlCountry"
                class="label"
              >{{ $tv('country', 'contactForm') }}*</label>
              <DropDown
                :data-items="listOfCountries"
                text-field="value"
                data-item-key="key"
                name="Country"
                @change="setCountry"
                :value="form.Country"
                :filterable="true"
                :rules="[getRequiredRule('country')]"
                id="ddlCountry"
                ref="validationFormCountry"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="contact__form-folding-container">
        <div class="folded-hr" />
        <div class="text-with-icon">
          <span
            class="no-break"
            @click="expanded = !expanded"
          >{{ $tv('MoreOptions', 'contactForm') }}</span>
          <div
            class="expand-wrapper"
            @click="expanded = !expanded"
          >
            <NavigationExpandMore
              class="svg-navigation-expand-more"
              :class="!expanded ? 'not-expanded' : 'expanded'"
            />
          </div>
        </div>
        <div class="folded-hr" />
      </div>
      <div
        class="contact__form-folding-container pure-g"
        v-if="expanded"
      >
        <div class="contact__body__formItem pure-u-1 pure-u-md-1-2 pure-u-xl-2-3">
          <div>
            <label
              for="company"
              class="label"
            >
              {{ $tv('company', 'contactForm') }}
            </label>
            <InputText
              type="text"
              id="company"
              v-model="form.Company"
              name="Company"
              :validable="true"
            />
          </div>
        </div>
        <div class="contact__body__formItem pure-u-1 pure-u-md-1-2 pure-u-xl-1-3">
          <div>
            <label
              class="label"
              for="telephone"
            >
              {{ $tv('telephone', 'contactForm') }}
            </label>
            <InputText
              type="text"
              id="telephone"
              name="Phone"
              v-model="form.Phone"
              :validable="true"
            />
          </div>
        </div>
      </div>

      <div
        class="contact__form-folding-container pure-g"
        v-if="expanded"
      >
        <div class="contact__body__formItem pure-u-1 pure-u-md-1-2 pure-u-xl-1-2">
          <div>
            <label
              class="label"
              for="street"
            >
              {{ $tv('street', 'contactForm') }}
            </label>
            <InputText
              type="text"
              id="street"
              name="Street"
              v-model="form.Street"
              :validable="true"
            />
          </div>
        </div>
        <div class="contact__body__formItem pure-u-1 pure-u-md-1-2 pure-u-xl-1-6">
          <div>
            <label
              class="label"
              for="zipcode"
            >
              {{ $tv('zipCode', 'contactForm') }}
            </label>
            <InputText
              type="text"
              id="zipcode"
              v-model="form.ZipCode"
              name="ZipCode"
              :validable="true"
            />
          </div>
        </div>
        <div class="contact__body__formItem pure-u-1 pure-u-md-1-2 pure-u-xl-1-3">
          <div>
            <label
              class="label"
              for="city"
            >
              {{ $tv('city', 'contactForm') }}
            </label>
            <InputText
              type="text"
              id="city"
              v-model="form.City"
              name="City"
              :validable="true"
            />
          </div>
        </div>
      </div>
      <div class="contact__body__formItem contact__body__formItem__message pure-u-1 contact__body__formItem__clear-margin">
        <label
          class="label"
          for="message"
        >{{ $tv('yourMessage', 'contactForm') }}*</label>
        <div class="input-wrapper">
          <InputText
            id="message"
            v-model="form.Message"
            name="Message"
            rows="4"
            :multiline="true"
            :rules="[getRequiredRule('yourMessage')]"
            ref="validationFormYourMessage"
            @input-value="form.Message = $event"
          />
        </div>
      </div>
      <div class="contact__form__checkboxes">
        <div class="contact__form__buttons pure-u-1">
          <Checkbox
            id="privacy"
            :checked="form.AcceptTermsAndConditions"
            :rules="[getCheckBoxRule('privacyAgreement')]"
            @change="form.AcceptTermsAndConditions = !form.AcceptTermsAndConditions"
            name="AcceptTermsAndConditions"
            ref="validationFormAcceptTermsAndConditions"
            :label="$tv({messageKey: 'privacy', messageValues:{link: $tv('privacyLink', 'contactForm')}}, 'contactForm') + '*'"
          />
        </div>
        <div class="contact__form__buttons pure-u-1">
          <Checkbox
            id="newsletter"
            name="SubscribeNewsletter"
            class="newsleter-checkbox"
            :checked="form.SubscribeNewsletter"
            @change="form.SubscribeNewsletter = !form.SubscribeNewsletter"
            :label="$tv('newsletter', 'contactForm')"
          />
        </div>
      </div>
      <div class="contact__form__save pure-u-1">
        <div
          class="mandatory-fields"
        >
          {{ $tv('mandatoryFields', 'contactForm') }}
        </div>
        <ReactiveButton
          :button-text="$tv('send', 'contactForm')"
          class="contact__body__formItem--margin"
          :is-loading="submitting"
          :is-primary-button="false"
          type="submit"
        />
      </div>
      <input
        type="hidden"
        :value="$i18n.locale"
      >
    </validator-form>
  </div>
</template>
<script>
import DropDown from '~/components/input-components/DropDown'
import Checkbox from '~/components/input-components/Checkbox.vue'
import InputText from '~/components/input-components/InputText.vue'
import ValidatorForm from '~/components/input-components/ValidationForm.vue'
import Countries from '~/assets/countries.json'
import {useContactService} from '@/composables/contact_service'
import {requiredValidationRule, emailValidationRule, requiredCheckboxValidationRule} from '~/assets/js/validations/common_validation_rules'
import {promisedTimeout} from '~/assets/js/utils/index.js'
import ReactiveButton from '~/components/ReactiveButton'
import NavigationExpandMore from '~/assets/icons/navigation_arrow_drop_down.svg?component'

export default defineComponent({
  components: {
    DropDown,
    Checkbox,
    ValidatorForm,
    InputText,
    ReactiveButton,
    NavigationExpandMore
  },
  setup() {
    const {postContactForm} = useContactService()

    return {
      postContactForm
    }
  },
  data() {
    return {
      messages: this.getMessages(),
      listOfCountries: Countries,
      newsletter: false,
      submitting: false,
      form: this.formData,
      defaultItemMessage: {
        text: this.$tv('selectAnOption', 'contactForm')
      },
      salutations: [{
        text: this.$tv('salutationMs', 'contactForm'),
        value: '1'
      },
      {
        text: this.$tv('salutationMr', 'contactForm'),
        value: '2'
      }],
      requiredRules: [requiredValidationRule],
      requiredEmailRules: [requiredValidationRule, emailValidationRule],
      expanded: true
    }
  },
  props: {
    formData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  methods: {
    getMessages() {
      return [
        {text: this.$tv('general', 'contactForm'), value: 'General_Request'},
        {text: this.$tv('technicalRequest', 'contactForm'), value: 'Technical_Request'},
        {text: this.$tv('procurementRequest', 'contactForm'), value: 'Procurement_Request'},
        {text: this.$tv('access', 'contactForm'), value: 'ECat_Access_Request'},
        {text: this.$tv('others', 'contactForm'), value: 'Others'}
      ]
    },
    setType(e) {
      this.form.Type = e.value
    },
    setCountry(e) {
      this.form.Country = e.value
    },
    setTitle(e) {
      this.form.Salutation = e.value
    },
    async submit(formValues) {
      this.submitting = true
      try {
        formValues.Language = this.$i18n.locale
        formValues.Country = formValues.Country?.key
        formValues.Type = formValues.Type?.value
        formValues.Salutation = formValues.Salutation?.value
        let res = await this.postContactForm(formValues)
        this.$gtm.trackEvent({
          event: 'contact',
          category: 'contact',
          action: 'send_contact_form',
          ecommerce: {'contact_reason': this.form.Type?.value}
        })
        if (res) {
          this.$toast.success(this.$tv('success', 'contactForm'))
          await this.$nextTick()
          await promisedTimeout(2000)
          this.$emit('close')
          this.$emit('clearFormData')
          this.submitting = false
          return
        }
      } catch (e) {
        // do nothing
      }

      this.$toast.error(this.$tv('error', 'contactForm'))
      await this.$nextTick()
      this.submitting = false
    },
    getRequiredRule(fieldName) {
      return requiredValidationRule(this.$tv(fieldName, 'contactForm'))
    },
    getCheckBoxRule(fieldName) {
      return requiredCheckboxValidationRule(this.$tv(fieldName, 'contactForm'))
    },
    getForm() {
      return this.form
    }
  }
})
</script>

<style lang="scss">
  @include breakpoint-up($md) {
    .contact__body__formItem:nth-child(2n+1) > div {
      padding-right: .5rem;
    }

    .contact__body__formItem:nth-child(2n) > div {
      padding-left: .5rem;
    }
  }

  @include breakpoint-up($xl) {
    .contact__body__formItem:nth-child(2n+1) > div,
    .contact__body__formItem:nth-child(2n) > div {
      padding-right: .5rem;
      padding-left: 0;
    }

    .contact__body__formItem:nth-child(3n+1) > div {
      padding-right: .5rem;
    }

    .contact__body__formItem:nth-child(3n+2) > div {
      padding-left: .5rem;
      padding-right: .5rem;
    }

    .contact__body__formItem:nth-child(3n) > div {
      padding-left: .5rem;
    }

    .contact__body__formItem--margin {
      margin-top: 1rem;
    }

    .contact__body__formItem.contact__body__formItem__clear-margin > div {
      padding-left: 0;
    }

    .contact__message {
      margin: 1rem 0;
    }
  }

  .contact .pure-u-1.pure-g {
    letter-spacing: -.31em;
  }

  .contact__header {
    margin-bottom: .5rem;

    .subheader {
      color: $color__fst_gray__100;
      margin-top: 1rem;

      &__description {
        padding-top: .3rem;
        font-weight: $base-text-font-weight-light;
        font-size: 1rem !important;
      }
    }
  }

  .contact__body__formItem__message {
    margin-top : 2rem
  }

  .contact__body__formItem__message textarea.k-textbox {
    height: auto;
  }

  .folded-hr {
    height: 0;
    background-color: $color__fst__gray__030;
    margin: .5rem 0;
    padding: 0;
    border-style: inset;
    border-width: .0625rem;
    width: 50%;
  }

  .contact__form-folding-container {
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
    align-items: center;

    .text-with-icon {
      display: flex;
      gap: .5rem;
      padding: 0 .5rem;
      color: $color__fst__cyan__100;
      align-items: center;

      .icon.icon--svg {
        font-size: 1.5rem;
        position: relative;
        top: .125rem;
      }

      .no-break {
        display: inline-block;
        white-space: nowrap;
      }

      .expand-wrapper {
        .svg-navigation-expand-more {
          cursor: pointer;
          font-size: 1.7rem;

          &.expanded {
            transform: rotate(180deg);
            transition: all .35s ease-in-out;
          }

          &.not-expanded {
            transform: rotate(0deg);
            transition: all .35s ease-in-out;
          }
        }
      }
    }

    @include breakpoint-down($sm) {
      .contact__body__formItem {
        margin-bottom: 1rem;
      }
    }

    @include breakpoint-down($md) {
      .contact__body__formItem {
        margin-bottom: 1rem;
      }
    }
  }

  .contact__form__checkboxes {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 2rem 0;

    .contact__form__buttons {
      label {
        font-weight: $base-text-font-weight-light;
      }
    }

    .newsleter-checkbox {
      font-weight: $base-text-font-weight-light !important;
    }
  }

  .contact__form__save {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: $base-text-font-weight-light;

    .mandatory-fields {
      font-weight: $base-text-font-weight-light;
    }
  }

</style>
